import React, { useEffect, useRef, useState } from "react";
import { useLocation } from 'react-router-dom';

import { IntlProvider, FormattedMessage } from 'react-intl';
import {
  UncontrolledCarousel,
  Container,
  Row,
  Col,
} from "reactstrap";
import RIRLandingHeader from "../components/RIRLandingHeader";
import RIRNavbar from "../components/RIRNavbar";
import { FacebookLink, YouTubeLink, TextBlock, MoreDatailsLink, YouTubePlayer } from "../components/SocNetLinks";

import Img1 from "../../../assets/projects/RIR/img/iyfr3.jpg";
import Img2 from "../../../assets/projects/RIR/icons/IYFR.png";
import Img3 from "../../../assets/projects/RIR/img/yacht10.jpg";
import Img4 from "../../../assets/projects/RIR/img/free_water.jpg";
import Img5 from "../../../assets/projects/RIR/img/yachta11.jpg";
import Img9 from "../../../assets/projects/RIR/img/yachta13.jpg";
import Img10 from "../../../assets/projects/RIR/img/yachta14.jpg";
import Img11 from "../../../assets/projects/RIR/img/yachta15.jpg";
import Img12 from "../../../assets/projects/RIR/img/yachta16.jpg";
import Img6 from "../../../assets/projects/RIR/icons/CherkasyFlot.png";
import Img7 from "../../../assets/projects/RIR/icons/KozackiVitryla.png";
import Img8 from "../../../assets/projects/RIR/img/Olga3.png";
import ImgUkrMap from "../../../assets/projects/RIR/img/ukrkarta.png";
import ImgWarShip from "../../../assets/projects/RIR/img/WarShip.jpg";
import ImgPdf from "../../../assets/projects/RIR/icons/pdf_icon.png";

const RDPHomePage = ({ currentLocale, handleChange }) => {
  const ga4 = 'G-0DQZF0RNTG';
  const location = useLocation();
  useEffect(() => {
    if (true || process.env.NODE_ENV === 'production') {
        window.gtag('config', ga4, {
        page_path: location.pathname,
        });
    }
  }, [location]);

  const myRef1 = useRef(null);
  const myRef2 = useRef(null);
  const myRef3 = useRef(null);
  const myRef4 = useRef(null);
  const myRef5 = useRef(null);

  useEffect(() => {
    document.documentElement.classList.remove("nav-open");
    document.body.classList.add("profile-page");

    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  }, []);

  return (
    <>
      <RIRNavbar
        myRef1={myRef1}
        myRef2={myRef2}
        myRef3={myRef3}
        myRef4={myRef4}
        myRef5={myRef5}
        currentLocale={currentLocale}
        handleChange={handleChange}
      />

      <RIRLandingHeader style={{ height: '50px' }} />

      <div className="main">
        <div className="section section-dark2 text-center" ref={myRef1}>
          <Container>
            <h2 className="title"><FormattedMessage id='about_region' /></h2>
            <Row>
              <Col md="4">
                <img src={Img1} width="350px" />
              </Col>
              <Col>
                <br /><TextBlock messageId="landitem_1" /><br />
              </Col>
              <Col md="4">
                <img src={ImgUkrMap} width="350px" />
              </Col>
            </Row>
            <Row>
                <YouTubePlayer width="100%" height="500px" href="https://www.youtube.com/embed/tdaqMI7tJt0" />
            </Row>
          </Container>
        </div>

        <div className="section text-left" ref={myRef2}>
          <Container>
            <h2 className="title"><FormattedMessage id='menu_2' /></h2>
            <Row>
              <Col>
                <TextBlock messageId="landitem_2_1" />
                <TextBlock messageId="landitem_2_2" />
                <MoreDatailsLink href="http://www.iyfr.net/new/about" />
              </Col>
              <Col md="4">
                <img src={Img2} width="350px" />
                <br /><br />
                <img src={Img11} width="350px" />
              </Col>
            </Row>
          </Container>
        </div>

        <div className="section section-dark2 text-center" ref={myRef3}>
          <Container>
            <h2 className="title"><FormattedMessage id='menu_3' /></h2>
            <Row>
              <Col md="4">
                <img src={Img3} width="350px" />
                <br /><br />
                <img src={Img9} width="350px" />
                <br /><br />
                <img src={Img12} width="350px" />
              </Col>
              <Col>
                <TextBlock messageId="landitem_3_1" />
                <TextBlock messageId="landitem_3_2" />
                <TextBlock messageId="landitem_3_3" />
                <TextBlock messageId="landitem_3_4" />
                <TextBlock messageId="landitem_3_5" />
              </Col>
            </Row>
          </Container>
        </div>

        <div className="section text-left" ref={myRef4}>
          <Container>
            <h2 className="title">World IYFR PLASTIC FREE WATERS Program</h2>
            <Row>
              <Col md="4">
                <img src={Img4} width="350px" />
                <br /><br />
                <img src={Img10} width="350px" />
              </Col>
              <Col>
                <TextBlock messageId="landitem_4_1" />
                <TextBlock messageId="landitem_4_2" />
                <TextBlock messageId="landitem_4_3" />
                <TextBlock messageId="landitem_4_4" />
                <YouTubeLink href="https://youtu.be/kdUcg9rLDMo" messageId="link_1" />
                <YouTubeLink href="https://youtu.be/PgmRvCehVIs" messageId="link_8" />
                <YouTubeLink href="https://drive.google.com/file/d/1tKC_OZA3Nk42w895KcZdcyZW_FVTVGTM/view" messageId="link_1" />
                <MoreDatailsLink href="https://iyfroperationplasticfreewaters.org/" />
              </Col>
            </Row>
          </Container>
        </div>

        <div className="section section-dark2 text-center" ref={myRef5}>
          <Container>
            <h2 className="title"><FormattedMessage id='menu___7' /></h2>
            <Row>
              <Col md="4">
                <img src={ImgWarShip} width="350px" />
                <br /><br />
              </Col>
              <Col>
                <TextBlock messageId="landitem_7" />
                <FacebookLink
                  href="https://www.facebook.com/872160239834858/posts/pfbid02oWGvwoxKqpgTBXLLjipLNTCJE9ede56SVhZbuejzW4cVSfna5s2yfgygp8k3c6fjl/?d=n"
                  title="Rotary Club Chrkasy"
                />
                <FacebookLink
                  href="https://www.facebook.com/872160239834858/posts/pfbid0q3igw1yucUvQWkrcLLbcxTBh2ZZGMt26ww5qFCZuimRJFKea4z1Gys6SBLdSm85Nl/?d=n/"
                  title="Rotary Club Chrkasy"
                />
                <FacebookLink
                  href="https://www.facebook.com/872160239834858/posts/pfbid02cK8XZ9owcf5qQBwHiJoy9Z2epZC5xUSqyiyZbkTVQ7Zw8whEBZGRzDahWgYbcUTPl/?d=n"
                  title="Rotary Club Chrkasy"
                />
                <FacebookLink
                  href="https://www.facebook.com/872160239834858/posts/pfbid02kQdCERR4g8RHFP7gzdo3F1YnZg8qDW7gwHh2TntMi6VJQTe93GaCeSJnAFE5ahdXl/?d=n"
                  title="Rotary Club Chrkasy"
                />
                <FacebookLink
                  href="https://www.facebook.com/872160239834858/posts/pfbid0ehihEo7CzEyEUq36HGToHWbaxz7dgAAkT79wyVUPYezLRPrjLZxpQQpniEM4g3rDl/?d=n"
                  title="Rotary Club Chrkasy"
                />
                <FacebookLink
                  href="https://www.facebook.com/260038631190117/posts/pfbid0whzjM67s6NiMqWoL5RnayKBGKxdWCgHzSwP6yVvt8vVwJYy67w3e6sYRtQbZF22el/?d=n"
                  title="Rotary Club Mukolaiv"
                />
                <FacebookLink
                  href="https://www.facebook.com/260038631190117/posts/pfbid0tBt8tJX5yNsPtvxJYPRCfsoj1JbVNdGJcNw3L51rkwPPCoWxyv3DxMJEqoiwzATXl/?d=n"
                  title="Rotary Club Mukolaiv"
                />
                <FacebookLink
                  href="https://www.facebook.com/260038631190117/posts/pfbid0quJQj2db6DGZaXQAJ6cPWsLvV4Yq9CM9cfoVj99eFttMFJyjFmtMjKRRHZrDqpE3l/?d=n"
                  title="Rotary Club Mukolaiv - Artem"
                />
                <FacebookLink
                  href="https://www.facebook.com/260038631190117/posts/pfbid02BKFsSXwzabdLYCeRrKGSu2dZFdG9xWxtKcMc7Pmj1ZTArtEChAzAVEpb8utpxmfSl/?d=n"
                  title="Rotary Club Mukolaiv"
                />
                <MoreDatailsLink href="https://www.facebook.com/100027918830905/posts/pfbid02yEtg3bw9WpNY5veDNE6nriG4vsfBY52W4Lqd7XDSN9EdhsAC6ra7qTQxQLnsjGTvl/?d=n" />
              </Col>
            </Row>
          </Container>
        </div>

        <div className="section text-left" ref={myRef4}>
          <Container>
            <h2 className="title"><FormattedMessage id='menu___8' /></h2>
            <Row>
              <Col md="4">
                <img src={Img10} width="350px" />
              </Col>
              <Col>
                <TextBlock messageId="landitem_8" />
              </Col>
            </Row>
          </Container>
        </div>

        <div className="section text-left" ref={myRef4}>
          <Container>
            <h2 className="title"><FormattedMessage id='menu___9' /></h2>
            <h5 className="description" style={{ textAlign: 'justify ' }}>
                  <img src={ImgPdf} width="30px" /><a href="https://res.cloudinary.com/tyko/raw/upload/v1691515662/IYFR_website/home/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%B0_12-13.08.2023_%D0%A7%D0%B5%D1%80%D0%BA%D0%B0%D1%81%D0%B8_%D0%B7%D1%83%D1%81%D1%82%D1%80%D1%96%D1%87_%D0%A3%D0%BA%D1%80%D0%B0%D1%96%CC%88%D0%BD%D1%81%D1%8C%D0%BA%D0%B8%D1%85_IYFR_%D0%A4%D0%BB%D0%BE%D1%82%D1%96%D0%B2_-_IYFR_Region_Ukraine_hpslto.docx">
                  Майбутні події</a>
                </h5>

                <h5 className="description" style={{ textAlign: 'justify ' }}>
                  <img src={ImgPdf} width="30px" /><a href="https://drive.google.com/file/d/1-1zDvJPAb7CqdszICtEV3o_I6kiHfLUw/view?usp=drive_link">
                  Future events</a>
                </h5>
          </Container>
        </div>

        <div className="section section-dark2 text-left" ref={myRef5}>
          <Container>
            <h2 className="title"><FormattedMessage id='menu_5' /></h2>
            <Row>
              <Col md="4">
                <img src={Img5} width="350px" />
              </Col>
              <Col>
                <h5 className="description" style={{ textAlign: 'justify ' }}>
                  <img src={Img8} width="50px" /><a href="https://www.facebook.com/olha.paliychuk"><FormattedMessage id='link_3' /></a>
                </h5>
                <h5 className="description" style={{ textAlign: 'justify ' }}>
                  <img src={Img6} width="60px" /><a href="https://www.facebook.com/CherkasyUF">Cherkasy Ukrainian Fleet</a>
                </h5>
                <h5 className="description" style={{ textAlign: 'justify ' }}>
                  <img src={Img7} width="50px" /><a href="https://www.facebook.com/kozatskivitryla/"><FormattedMessage id='link_4' /></a>
                </h5>
                <br /><br />
                <h5 className="description" style={{ textAlign: 'justify ' }}>
                  <img src={ImgPdf} width="30px" /><a href="https://drive.google.com/file/d/1dNOTldAWYZRpJhvii8edXAU3ZFHH7JVu/view">
                    <FormattedMessage id='link_5' /></a>
                </h5>
                <h5 className="description" style={{ textAlign: 'justify ' }}>
                  <img src={ImgPdf} width="30px" />
                  <a href="https://drive.google.com/file/d/1f7tMNPRfg_evUAA_BPK8rN72H7WLGLf8/view?usp=sharing">
                    <FormattedMessage id='link_6' />
                  </a>
                </h5>
                <h5 className="description" style={{ textAlign: 'justify ' }}>
                  <img src={ImgPdf} width="30px" /><a href="https://drive.google.com/file/d/1iVsl7UR-svxSfWsNcrDD479g8Ea8nC_9/view?usp=sharing">
                    <FormattedMessage id='link_7' /></a>
                </h5>
                <YouTubePlayer width="100%" height="300px" href="https://www.youtube.com/embed/Ho_YAaeoaXg" />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default RDPHomePage;