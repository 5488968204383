import React, { useEffect, useState } from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';
import RIRNewsHeader from "../components/RIRNewsHeader";
import RIRNewsNavbar from "../components/RIRNewsNavbar";
import styled from "styled-components";
import { LOCALES } from "../../../i18n/locales";
import {
  Container,
  Row,
  Col,
  Link,
} from "reactstrap";
import {getNewsData} from '../components/api/dbNews';

const NewsContainer = styled(Container)`
  border: ${props => props.wborder}px solid lightblue;
`;

const NewsItemDiv = styled.div`
  margin-bottom: 10px;
  border: 1px solid blue
`;

const TextItemDiv = styled.div`
  margin-bottom: 10px;
`;

const RDPNewsPage = ({ currentLocale, handleChange, adminMode }) => {
  document.documentElement.classList.remove("nav-open");

  const [arrNews2, setArrNews2] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const fetchFBData = async () => {
    try {
      const response = await getNewsData();

      setArrNews2(response.data.reverse());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchFBData();
  }, []);

  const loadMoreData = () => {
    // Fetch more data here
  };

  return (
    <>
      <RIRNewsNavbar currentLocale={currentLocale} handleChange={handleChange} />
      <RIRNewsHeader />
      <div className="main text-dark">
        <InfiniteScroll
          dataLength={arrNews2.length}
          next={loadMoreData}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
          endMessage={<p>No more news to load</p>}
        >
          <NewsList newsList={arrNews2} currentLocale={currentLocale} />
        </InfiniteScroll>
      </div>
    </>
  );
};

export default RDPNewsPage;




const NewsList = ({ newsList, currentLocale }) => {
  return (
    <div>
      {newsList.map(item => (
        item.hided !== null && !item.hided ? (
        <div
          key={item.id}
          className="text-left"
          style={{ margin: "10px", marginBottom: "40px" }}
        >
          <NewsItem newsItem={item} currentLocale={currentLocale} />
        </div> ) : null
      ))}
    </div>
  );
};

const NewsItem = ({ newsItem, currentLocale }) => {
  const imgList = newsItem?.imgList;
  const text_ukr =
    currentLocale === LOCALES.UKRAINIAN
      ? newsItem.text_ukr
      : newsItem.text_eng;
  const wBorder = 1;
  const text = newsItem.title;

  const showImages = (imgList) => {
    return (
      <div>
        {imgList &&
          imgList.map(item => (
            <div style={{ margin: "10px" }}>
              <img src={item} width="250px" />
            </div>
          ))}
      </div>
    );
  };

  return (
    <NewsContainer wborder={wBorder}>
      <Row>
        <Col xs="12">
        <h6 className="description">[{newsItem.id}] {newsItem.date} <a class=" active" target="_blank" href={newsItem.url}>source link</a></h6>
        </Col>
      </Row>

      <Row>
        <Col md="4">{showImages(imgList)}</Col>
        <Col>
          <p style={{ whiteSpace: "pre-wrap" }}>{text_ukr}</p>
        </Col>
      </Row>
    </NewsContainer>
  );
};