import { LOCALES } from './locales'

export const messages = {
    [LOCALES.ENGLISH]: {
        learn_to: `Hello, let's learn how to use React-Intl`,
        price_display:
            'How {n, number, ::currency/USD} is displayed in your selected language',
        number_display:
            'This is how {n, number} is formatted in the selected locale',
        start_today: 'Start Today: {d, date}',
        // меню
        about_region: 'About IYFR Region Ukraine',
        menu_2: 'About IYFR',
        menu_3: 'Mission and Goals',
        menu_4: 'Plastic Free Waters IYFR Program',
        menu_5: 'Contacts',
        menu_6: 'News',
        menu___7: 'Ukrainian IYFR Fleets and D2232 Rotary clubs activities during the war (24th, February - …)',
        menu___8: 'D2232 and 4 IYFR Ukrainian Fleets Humanitarian Aid Project “Help Young Sailors”',
        menu_to_home: 'To Home',
        menu___9: 'Future events',
        News: 'News',
        //header
        header_region: "Region Ukraine",
        //landitems
        more_details: "more details here ...",
        landitem_1: "This is a new division of the Fellowship, separated from the East Europe Region due to the increase of IYFR members in Ukraine and District 2232 from among the members of Rotary clubs in different cities of our country.\n",
        landitem_2_1: "Members of Rotary clubs in different countries are successful business and community leaders, representatives of various professions, who fulfill joint humanitarian service, maintain high ethical standards, help build mutual understanding and peace in the world.\n",
        landitem_2_2: "The International Yacht Rotary Society began operations in the United Kingdom in 1947 and is recognized as the oldest of such Rotary fellowships. It became the first of many fellowships to be formed as part of Rotary International.",
        landitem_3_1: "The International Yachting Fellowship of Rotarians (IYFR) combines the adoption of the principles of Rotary International (RI) with a love of boats and the sea, rivers and inland local water resources, and uses the common interest of members of various Rotary clubs in yachting and navigation while promoting areas of focus of Rotary Service.",
        landitem_3_2: "The goals of the International Yacht Rotary Fellowship are to help strengthen international understanding, goodwill, and peace through a global community of business leaders and professionals united by the ideals of serving local communities and improving intercountry connections",
        landitem_3_3: "The Fellowship helps promote and develop the public image of RI and IYFR through cruises, yachting, racing, sailing, kayaking and other activities involving boats and other events in the inland water reservoirs and along their coastline.",
        landitem_3_4: "And IYFR members promote high standards of navigation and adhere to the recognized customs of yachting and etiquette on the water, as well as support the environmental direction of maintaining the purity of water and protecting the oceans  from the plastic.",
        landitem_3_5: "Rotary IYFR representatives jointly support socially important initiatives, projects, and opportunities for Rotary Service, both on the water and along the rivers, lakes, seas, and other water reservoirs.",
        landitem_4_1: "Plastic remains as it is for hundreds of years, so plastic waste can remain in the environment for centuries. More than 1 million tons of plastic are produced in the world every day. After use, much of the plastic, which is not collected and processed, due to carelessness and local insufficiency falls into rivers and then into the seas and oceans.",
        landitem_4_2: "Floating plastic accumulates in certain parts of the world's oceans, and at the same time it kills fish and marine animals that swallow it. It partially decomposes into microplastic particles and sinks to the bottom, which worsens the ecology of the marine environment and various life forms.",
        landitem_4_3: "This is only a visible part of plastic waste, but we must consider and take into account the risks of accumulation of nanoparticles of plastic and microfibers, which originate mainly from the threads of artificial textile items of clothing that are released during washing.",
        landitem_4_4: "Because of all this, the amount of plastic produced increases the weight of debris that accumulates in the oceans, and will soon be equal to the weight of all fish in the waters of the oceans in an incredibly short time.",
        landitem_7:   "Social resources:",
        landitem_8:   "DEVELOPMENT OF SAILING IN CHERKASY REGION, UKRAINE AND IN THE WORLD, CONNECTION WITH IYFR AREAS OF WORK. The Ukrainian school of sailing has developed many world-class athletes. At the same time, since the former Soviet system of sports development was abolished, it has practically stopped developing. International experience clearly shows that there are three key factors in the development of a dynamic sports industry: yacht clubs, properly formed and regulated on a uniform basis; popularization of sports among young people; and the localization of sailing, which makes the sport more open and accessible.",
        //links
        link_1:     "Cherkasy IYFR Ukrainian Fleet",
        link_2:     "Cherkasy IYFR Ukrainian Fleet",
        link_3:     "Olha Paliychuk",
        link_4:     "Cossack’s Sails",
        link_5:     "Leaflet \"Under the Sails\" UA",
        link_6:     "Leaflet \"Under the Sails\" EN",
        link_7:     "Charter Kharkiv 29.08.2021",
        link_8:     "International Yachting Fellowship of Rotarians IYFR in Ukraine",

    },
    [LOCALES.UKRAINIAN]: {
        learn_to: 'Привет, научимся использовать React-Intl',
        price_display:
            'Как {n, number, ::currency/RUB} отображается на выбранном языке',
        number_display:
            'Вот как {n, number} форматируется на основе выбранной локации',
        start_today: 'Начни сегодня: {d, date}',
        // меню
        about_region: 'Про IYFR Регіон Україна',
        menu_2: 'Про IYFR',
        menu_3: 'Місія та Цілі',
        menu_4: 'Вода без Пластику IYFR',
        menu_5: 'Контакти',
        menu_6: 'Новини',
        menu___7: 'Діяльність членів Українських IYFR Флотів та Ротарі клубів Д2232 під час війни (24.02.2022-…)',
        menu___8: 'Спільний гуманітарний проект Д2232 та 4 Українських IYFR Флотів допомози дитячим вітрильним школам України',
        menu_to_home: 'На Головну',
        menu___9: 'Майбутні події',
        News: 'Новини',
        //header
        header_region: "Регіон Украіна",
        //landitems
        more_details: "детальніше тут...",
        landitem_1: "Це новий підрозділ Товариства, що відокремився від Region East Europe за рахунок збільшення членів IYFR в Україні та Дистрикті 2232 серед членів Ротарі клубів в різних містах нашої країни.\n",
        landitem_2_1: "Міжнародне Яхтове Ротарійське Товариство, розпочало роботу у 1947 році у Великій Британії та визнане як найстаріше із подібних спільнот та об’єднань ротарійців за інтересами. Воно стало першим із багатьох дружніх Товариств, що були утворені у рамках діяльності міжнародної організації Ротарі Інтернешнл.",
        landitem_2_2: "Членами Ротарі клубів в різних країнах є успішні бізнесмени та лідери, представники різних професій, які здійснюють спільно гуманітарне Служіння, підтримують високі етичні стандарти, допомагають разом будувати взаєморозуміння і мир у світі.",
        landitem_3_1: "International Yachting Fellowship of Rotarians (IYFR) поєднує в собі прийняття принципів Rotary International (RI) з любов’ю до човнів і моря, річок та внутрішніх місцевих водних ресурсів, а також використовує спільний інтерес членів різних Ротарі клубів до яхтингу та мореплавства як можливість просування і сприяння напрямкам Ротарійського Служіння.",
        landitem_3_2: "Цілі представників Міжнародного Яхтового Ротарійського Товариства допомагають зміцненню міжнародного порозуміння, доброї волі та миру за допомогою світового співтовариства ділових та професійних осіб, об’єднаних ідеалами Служіння місцевим громадам та покращенню міждержавних зв‘язків.",
        landitem_3_3: "Товариство допомагає пропагувати і розвивати публічний імідж RI та IYFR за допомогою круїзів, яхтингу, перегонів, вітрильного спорту, каякінгу та іншої діяльності із залученням човнів та інших активних подій на акваторії водоймищ та вздовж їх берегової лінії.",
        landitem_3_4: "А члени IYFR сприяють високим стандартам мореплавства та дотримуються визнаних звичаїв яхтингу і етикету на воді, а також підтримують екологічний напрямок збереження чистоти води та захисту від пластику Світового океану.",
        landitem_3_5: "Ротарійці представники IYFR надають спільно підтримку соціально важливим ініціативам, проектам і можливостям для Ротарійського Служіння, як на воді, так і вздовж узбережжя річок, озер, морів та інших водоймищ.",
        landitem_4_1: "Пластик залишається таким, яким він є протягом сотень років, тому пластикове сміття може залишатися в навколишньому середовищі століттями. Щодня у світі виробляється більше 1 мільйона тонн пластику. Після використання значна частина пластику, який не збирається та не обробляється, через необережність та місцеву недостатність потрапляє у річки, а потім у моря та океани.",
        landitem_4_2: "Плаваючий пластик накопичується в певних частинах Світового океану, і в той же час він вбиває рибу і морських тварин, які їх ковтають. Частково він розпадається на мікропластикові частинки і опускається на дно, що погіршує екологію морського оточення та різних форм життя.",
        landitem_4_3: "Це лише видима частина пластичних відходів, але ми повинні розглянути і врахувати ризики від скупчення наночастинок пластику та мікроволокон, які походять переважно з ниток штучних текстильних елементів одягу, що виділяються під час прання.",
        landitem_4_4: "Через все це кількість виробленого пластику збільшує вагу сміття, що накопичується в океанах, і скоро буде дорівнювати вазі всіх риб у водах Світового океану за неймовірно короткий час.",
        landitem_7: "Соціальні ресурси:",
        landitem_8: "ШКОЛИ ВІТРИЛЬНОГО СПОРТУ ЗА ПІДТРИМКИ IYFR. Українська школа вітрильного спорту виховала багато спортсменів світового рівня. Міжнародний досвід чітко свідчить про те, що є три ключові фактори розвитку динамічної спортивної галузі: яхт-клуби, сформовані та регульовані належним чином на єдиних засадах; популяризація спорту серед молоді; локалізація вітрильних перегонів, яка робить спорт більш відкритим та доступним.",
        //links
        link_1:     "Черкаський IYFR Український Флот",
        link_2:     "Черкаський IYFR Український Флот",
        link_3:     "Ольга Палійчук",
        link_4:     "Козацькі Вітрила",
        link_5:     "Інформаційний буклет \"Під вітрилами\" УКР",
        link_6:     "Інформаційний буклет \"Під вітрилами\" АНГЛ",
        link_7:     "Презентація Чартер Харьків 29.08.2021",
        link_8:     "Міжнародне яхтингове товариство ротарійців IYFR в Україні",
    },
}
