const ENV_TYKO_UPLOAD_PRESET = 'lto2m1qq';

export const uploadImage = async (postId, profileImage) => {
    //e.preventDefault();
    //setIsLoading(true);
    console.log("uploadImage uploading... id="+postId);
    try {
      let imageURL;
      if (
        profileImage /*&& (
          profileImage.type === 'image/png' || 
          profileImage.type === 'image/jpg' || 
          profileImage.type === 'image/jpeg' 
          )*/
        ) {
            const uploadData = {
                file: profileImage,
                clode_name: 'tyko',
                upload_preset: ENV_TYKO_UPLOAD_PRESET,
                folder: "IYFR_website/" + postId + "/"
              };

          const response = await fetch(
            "https://api.cloudinary.com/v1_1/tyko/upload",
            {
              method: "post",
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(uploadData),
            }
          )
          //setIsLoading(true);
          const imgData = await response.json();
          imageURL = imgData.url;
          //setImagePreview(null);
          console.log("uploadImage uploaded");
        }
        //alert(imageURL);
        console.log("uploadImage finished = "+imageURL);
        return imageURL;
    } catch (error) {
      console.log(error);
      //setIsLoading(true);
    }
  }