
import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
import { LOCALES } from '../../../i18n/locales'

// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import RZH_ICON from "../../../assets/projects/RIR/icons/IYFR_region2.png"
import {FormattedMessage} from "react-intl";

const RIRNavbar = (props) => {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const executeScroll1 = () => props.myRef1.current.scrollIntoView();
  const executeScroll2 = () => props.myRef2.current.scrollIntoView();
  const executeScroll3 = () => props.myRef3.current.scrollIntoView();
  const executeScroll4 = () => props.myRef4.current.scrollIntoView();
  const executeScroll5 = () => props.myRef5.current.scrollIntoView();
  const currentLocale = props.currentLocale;
  const handleChange = props.handleChange;

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  const [currency, setCurrency] = React.useState('');
  const [dropDownOpen2, setDropdownOpen2] = React.useState('');
  const toggle2 = () => setDropdownOpen2(dropDownOpen => !dropDownOpen);
  const handleChange2 = (code) => setCurrency(currency => code);
  const handleTargetChange = ({ target: { value } }) => {
    handleChange(value);
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 199 ||
        document.body.scrollTop > 199
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 200 ||
        document.body.scrollTop < 200
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  const languages = [
    { name: 'Eng', code: LOCALES.ENGLISH },
    { name: 'Укр', code: LOCALES.UKRAINIAN },
  ];

  var currentLocaleItem = { name: 'Eng', code: LOCALES.ENGLISH };
  for (let item of languages) {
    if( item.code === currentLocale )
      currentLocaleItem = item;
  }
  var nextLocaleItem = currentLocaleItem;
  if( currentLocaleItem.code === LOCALES.ENGLISH )
    nextLocaleItem = { name: 'Укр', code: LOCALES.UKRAINIAN };
  if( currentLocaleItem.code === LOCALES.UKRAINIAN )
    nextLocaleItem = { name: 'Eng', code: LOCALES.ENGLISH };
  const toggleLocal = () => {
    handleChange(nextLocaleItem.code);
  };

  return (

    <Navbar
      className={classnames("fixed-top", navbarColor)}
      color-on-scroll="200"
      expand="lg"
    >
      <Container fluid>


        <div className="navbar-translate">
          <NavbarBrand
              data-placement="bottom"
              //to="/index"
              target="_blank"
              //title="Coded by Creative Tim"
              //tag={Link}
          >

            <img style={{
              //border: "1px solid red",
              margin: "-60px", marginLeft: "-40px"
            }} src={RZH_ICON} height="130px" />
          </NavbarBrand>

          <div>
            <button
                aria-expanded={navbarCollapse}
                className={classnames("navbar-toggler navbar-toggler", {
                  toggled: navbarCollapse,
                })}
                onClick={toggleLocal}
            >
              {nextLocaleItem.name}
            </button>

          <button
              aria-expanded={navbarCollapse}
              className={classnames("navbar-toggler navbar-toggler", {
                toggled: navbarCollapse,
              })}
              onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
          </div>
        </div>

        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
            {/*Home
              FUN Training
              Fun Edutainment
              Fun Camp
              Контакты*/
            }
            <NavItem>
              <NavLink
                  //to="/index"
                  onClick={executeScroll1}
                  tag={Link}
              >
                <i className="nc-icon none" /><FormattedMessage id='about_region' />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                //href="https://demos.creative-tim.com/paper-kit-react/#/documentation?ref=pkr-examples-navbar"
                  onClick={executeScroll2}
                  tag={Link}
              >
                <i className="nc-icon none" /><FormattedMessage id='menu_2' />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                  //href="https://demos.creative-tim.com/paper-kit-react/#/documentation?ref=pkr-examples-navbar"
                  onClick={executeScroll3}
                  tag={Link}
              >
                <i className="nc-icon none" /><FormattedMessage id='menu_3' />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                  //href="https://demos.creative-tim.com/paper-kit-react/#/documentation?ref=pkr-examples-navbar"
                  onClick={executeScroll4}
                  tag={Link}
              >
                <i className="nc-icon none" /><FormattedMessage id='menu_4' />
              </NavLink>
            </NavItem>
              <NavItem>
                  <NavLink
                      //href="https://demos.creative-tim.com/paper-kit-react/#/documentation?ref=pkr-examples-navbar"
                      onClick={executeScroll5}
                      tag={Link}
                  >
                      <i className="nc-icon none" /><FormattedMessage id='menu_5' />
                  </NavLink>
              </NavItem>
            <NavItem>
              <NavLink
                  to="/region_news"
                  //onClick={executeScroll1}
                  tag={Link}
              >
                <i className="nc-icon none" /><FormattedMessage id='menu_6' />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                  data-placement="bottom"
                  //href="https://www.facebook.com/CreativeTim?ref=creativetim"
                  target="_blank"
                  title="Like us on Facebook"
              >

                <p className="d-lg-none"> </p>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.facebook.com/CherkasyUF/"
                //target="_blank"
                title="Черкаси в Facebook"
              >
                <i className="fa fa-facebook-square" />
                <p className="d-lg-none">Черкаси</p>
              </NavLink>
            </NavItem>

            {/*<Dropdown isOpen={dropDownOpen2} toggle={toggle2} >
              <DropdownToggle color="link" caret className="dropdown-toggle">
                  {currentLocaleItem.name}
              </DropdownToggle>
              <DropdownMenu className="currency-dropdown">
                  {languages.map(({ name, code }) => (
                      <DropdownItem onClick={handleTargetChange} value={code}>{name}</DropdownItem>
                  ))}
              </DropdownMenu>
            </Dropdown>*/}
            <button
                className="btn btn-link "
                onClick={toggleLocal}
            >
              {nextLocaleItem.name}
            </button>

          </Nav>
        </Collapse>


      </Container>
    </Navbar>
  );
}

export default RIRNavbar;
