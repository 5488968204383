import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { IntlProvider } from 'react-intl'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import {getNewsData, addNewsData, updateNewsData} from '../components/api/dbNews';
import {uploadImage} from '../components/api/dbFileUpload';
//import {messages} from '../../../i18n/messages'
// reactstrap components
import {
    UncontrolledCarousel,
    Container,
    Row,
    Col,
    Button,
    Tooltip,
} from "reactstrap";

import styled from "styled-components";
import {LOCALES} from "../../../i18n/locales";

const NewsContainer = styled(Container)`
  border: ${props => props.wborder}px solid lightblue;
`;
const TextItemDiv = styled.div`
    margin-bottom: 10px;
`;

const RIRNewsAddPage = ({ currentLocale, handleChange, adminMode }) => {
    document.documentElement.classList.remove("nav-open");
    
    const [arrNews2, setArrNews2] = useState([]);

    const fetchFBData = async () => {
        
        const response = await  getNewsData();
        setArrNews2(response.data.reverse());
        //setLoading(false);
          
    };

    useEffect(() => {
        fetchFBData();
    }, []);



    var options = {
        //era: 'long',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        //weekday: 'long',
        //timezone: 'UTC',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    };

    const showAllNews = (allNews, locale) => {
        if(!allNews)
            return;
        if(allNews.length <= 0)
            return;
            
        return (
            <div>
                {
                    allNews ? 
                    allNews.map((item) => (
                        <div className="section text-left">
                            {showNewsItem(item, locale)}
                        </div>
                        )
                    ) : null
                }
                
            </div>
        )
    };

    const onClickId = (id) => {
        setSelectedId(id);
        console.log('set selected id='+id);
    }

    const showNewsItem = (newsItem, locale) => {
        if(!newsItem)
            return;
        const imgList = newsItem ?  newsItem.imgList : null;
        //const textList = "t1";//newsItem ?  newsItem.textList : null;
        const text_ukr = newsItem.text_ukr;
        const wBorder = 0;
        const text = newsItem.title;
        return (
            <NewsContainer wborder={wBorder}>
                
                {text ? <Row>
                    <h2 className="title">
                        <TextItemDiv>{text}</TextItemDiv>
                    </h2>
                </Row> : null}
                <Row>
                    <Col><Button className={!newsItem.hided ? "btn btn-primary" : ""} size="sm" onClick={() => onClickId(newsItem.id)}>{newsItem.id}</Button>
                    {newsItem.hided ? <>(hided)</> : null}
                     {newsItem.date}</Col>
                </Row>
                <Row>
                    <Col>
                    <>
                    {newsItem.url} 
                    {newsItem.url ? 
                    <Button size="sm" title="Copy to clipboard" onClick={() => copyToClipboard(newsItem.url)}>
                        <FontAwesomeIcon icon={faCopy} />
                    </Button> : null}
                    </>
                    </Col>
                </Row>
                <Row>
                    <Col>{showImages(imgList)}</Col>
                    <Col>
                    <p style={{whiteSpace: 'pre-wrap', border: '1px solid gray'}}>{text_ukr}</p>
                    </Col>
                    <Col>
                    <p style={{whiteSpace: 'pre-wrap', border: '1px solid gray'}}>{newsItem.text_eng}</p>
                    </Col>
                </Row>
            </NewsContainer>
        )
    };

    const showImages = (imgList) => {
        return (
            <div>
            {
                imgList ?
                imgList.map((item) => (
                    <img src={item} width="150px" />
                    )
                ) : null
            }
            </div>
        )
    };
  
    const [itemId, setItemId] = useState(-1);
    const [itemDate, setItemDate] = useState('');
    const [itemUrl, setItemUrl] = useState('');
    const [itemDetail, setItemDetail] = useState('');
    const [itemDetailEng, setItemDetailEng] = useState('');
    const [itemName1, setItemName1] = useState('');
    const [itemName2, setItemName2] = useState('');
    const [itemName3, setItemName3] = useState('');
    const [itemHided, setItemHided] = useState(false);

    const [selectedId, setSelectedId] = useState(-1);

    useEffect(() => {
        setItemId(Number(selectedId));

        //const findIndexById = (items, id) => items.findIndex(item => item.id === id);
        const index = findIndexById(arrNews2, selectedId);

        if (arrNews2 && index>=0 && arrNews2[index]) {
            console.log('==== text_ukr='+arrNews2[index].text_ukr)
            console.log('==== url='+arrNews2[index].url)

            setItemDate(arrNews2[index].date);
            setItemUrl(arrNews2[index].url);
            setItemDetail(arrNews2[index].text_ukr);
            setItemDetailEng(arrNews2[index].text_eng);
            setItemName1('');
            setItemName2('');
            setItemName3('');
            if (arrNews2[index].imgList && arrNews2[index].imgList[0])
                setItemName1(arrNews2[index].imgList[0]);
            if (arrNews2[index].imgList && arrNews2[index].imgList[1])
                setItemName2(arrNews2[index].imgList[1]);
            if (arrNews2[index].imgList && arrNews2[index].imgList[2])
                setItemName3(arrNews2[index].imgList[2]);
            setItemHided(arrNews2[index].hided?arrNews2[index].hided:false);
        }
        
    }, [selectedId]);

    const addItem = async () => {

		const t1 = await reuploadToCloud(itemName1,itemId);
        const t2 = await reuploadToCloud(itemName2,itemId);
        const t3 = await reuploadToCloud(itemName3,itemId);
        const item = {
            id: itemId,
            url: itemUrl,
            title: "",
            text_ukr: itemDetail,
            text_eng: itemDetailEng,
            date: itemDate,
            imgList: [
                t1,
                t2,
                t3,
            ],
            hided: itemHided,
        };

        await addNewsData(item);
        
        setItemId(Number(itemId)+1);
        setItemDate('');
        setItemUrl('');
        setItemDetail('');
        setItemDetailEng('');
        setItemName1('');
        setItemName2('');
        setItemName3('');
        setItemHided(false);
        
        fetchFBData();
    };

    const newItem = async () => {

        setSelectedId(arrNews2.length+1);
        setItemId(arrNews2.length+1);
        setItemDate('');
        setItemUrl('');
        setItemDetail('');
        setItemDetailEng('');
        setItemName1('');
        setItemName2('');
        setItemName3('');
        setItemHided(false);
        
        fetchFBData();
    };
    const saveItem = async () => {
        
        const t1 = await reuploadToCloud(itemName1,itemId);
        const t2 = await reuploadToCloud(itemName2,itemId);
        const t3 = await reuploadToCloud(itemName3,itemId);
        const item = {
            id: itemId,
            url: itemUrl,
            title: "",
            text_ukr: itemDetail,
            text_eng: itemDetailEng,
            date: itemDate,
            imgList: [
                t1,
                t2,
                t3,
            ],
            hided: itemHided,
        };

        await updateNewsData(itemId, item);
      
        setSelectedId(-1);
        //setItemId(Number(itemId)+1);
        setItemDate('');
        setItemUrl('');
        setItemDetail('');
        setItemDetailEng('');
        setItemName1('');
        setItemName2('');
        setItemName3('');
        setItemHided(false);
        
        fetchFBData();
    }

    const reuploadToCloud = async (imgurl, postid) => {
        console.log("===========1 newyurl = "+imgurl);
        if (imgurl && imgurl.includes("fbcdn.net")) {
            const newurl = await uploadImage(postid, imgurl);
            console.log("reuploadToCloud newyurl = "+newurl);
            return newurl;
        } else {
            return imgurl;
        }    
    }

    const reuploadImages = async () => {
        const t1 = await reuploadToCloud(itemName1,itemId);
        const t2 = await reuploadToCloud(itemName2,itemId);
        const t3 = await reuploadToCloud(itemName3,itemId);
        //console.log("reuploadImages newyurl2 = "+t1);
        setItemName1(t1);
        setItemName2(t2);
        setItemName3(t3);
    }

    const translate = async () => {
        const OPENAI_KEY = "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";
        try {
          const response = await axios.post(
            'https://api.openai.com/v1/completions',
            {
              prompt: `Translate the following Ukrainian text to English, but keep URLs and emojis unchanged : ${itemDetail}`,
              max_tokens: 560,
              model: "text-davinci-003",
              temperature: 0,
            },
            {
              headers: {
                'Authorization': `Bearer ${OPENAI_KEY}`,
                'Content-Type': 'application/json'
              }
            }
          );
    
          setItemDetailEng(response.data.choices[0].text.trim());
        } catch (error) {
          console.error(error);
        }
    };
      
    const restoreEngDetails = async () => {
        const index = findIndexById(arrNews2, selectedId);
        if (index >= 0) {
            setItemDetailEng(arrNews2[index].text_eng);
        }
    }
    const restoreUkrDetails = async () => {
        const index = findIndexById(arrNews2, selectedId);
        if (index >= 0) {
            setItemDetail(arrNews2[index].text_ukr);
        }
    }

    const copyToClipboard = (urlText) => {
        navigator.clipboard.writeText(urlText).then(function() {
          console.log('Copying to clipboard was successful!');
        }, function(err) {
          console.error('Could not copy text: ', err);
        });
    }

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            color: 'black',
        },
        fixedBlock: {
            position: 'sticky',
            top: 0,
            _left: '0px',
            height: '550px',
            backgroundColor: '#f5f5f5',
            marginBottom: '3px',
            marginLeft: '40px',
            zIndex: 100,
        },
        scrollBlock: {
            overflowY: 'scroll',
        },
        content: {
            _marginTop: '10px',
            lineHeight: '1.6'
        }
    };
    
    const findIndexById = (items, id) => items.findIndex(item => item.id === id);

    const index = findIndexById(arrNews2, selectedId);
    const isUkrChanged = arrNews2 && index >= 0 && itemDetail !== arrNews2[index].text_ukr;
    const isEngChanged = arrNews2 && index >= 0 && itemDetailEng !== arrNews2[index].text_eng;
    return (
        
        <div style={styles.container}>
        <div style={styles.fixedBlock}>

            <h3>Post</h3>
            <Row style={{ border: '1px solid green' }}>
            <Col  md="2" style={{ width: '200px', background: 'lightgray', border: '1px solid green' }} >
                <p><label>ID:</label>
                <input 
                type="text" 
                readOnly
                value={itemId} 
                onChange={e => setItemId(e.target.value)} 
                /></p>
                <p><label>Date:</label>
                <input 
                type="text" 
                value={itemDate} 
                onChange={e => setItemDate(e.target.value)} 
                /></p>

                <p><label>Url:</label>
                <input 
                type="text" 
                value={itemUrl} 
                onChange={e => setItemUrl(e.target.value)} 
                />
                </p>

                <p><label>img1:</label>
                <input 
                type="text" 
                value={itemName1} 
                onChange={e => setItemName1(e.target.value)} 
                /></p>
                <p><label>img2:</label>
                <input 
                type="text" 
                value={itemName2} 
                onChange={e => setItemName2(e.target.value)} 
                /></p>
                <p><label>img3:</label>
                <input 
                type="text" 
                value={itemName3} 
                onChange={e => setItemName3(e.target.value)} 
                /></p>
                <p><label>
                    <input 
                    type="checkbox"
                    checked={itemHided}
                    onChange={e => setItemHided(e.target.checked)}
                    />
                    hide
                </label></p>
                { arrNews2.length+1 === itemId ?
                    <button onClick={addItem}>Add Item</button>
                    : 
                    <>
                    <button disabled={itemId===-1} onClick={saveItem}>Save Item</button><br/><br/>
                    <button onClick={newItem}>New Item</button>
                    </>
                }
                <button onClick={reuploadImages}>TestImages</button>
                
            </Col>

            <Col style={{border: isUkrChanged?'3px solid red':"0px", height: '350px'}} >
                <label>українською:</label>
                <button onClick={restoreUkrDetails}>скинути зміни</button>
                <textarea style={{width: '100%', height: '100%'}}
                value={itemDetail} 
                onChange={e => setItemDetail(e.target.value)} 
                />
            </Col>
            <Col style={{border: isEngChanged?'3px solid red':"0px"}} >
                <label>english:</label>
                <button onClick={translate}>авто переклад з української</button>
                <button onClick={restoreEngDetails}>скинути зміни</button>
                <textarea style={{width: '100%', height: '90%'}}
                value={itemDetailEng} 
                onChange={e => setItemDetailEng(e.target.value)} 
                />
            </Col>
            </Row>
        </div>
        <div style={styles.scrollBlock}>

                    <NewsContainer wborder={1} />

                    <iframe src="https://www.facebook.com/watch/?v=589120288916619"></iframe>
                    

                    {showAllNews(arrNews2, currentLocale)}

            </div>
        </div>
        
    );
}

export default RIRNewsAddPage;
