import axios from 'axios';

const SERVER_URL = "http://localhost:3000/api/news";
//const SERVER_URL = 'https://protected-plateau-39064-967c52c1b4d8.herokuapp.com/api/news';

export const getNewsData = () => {
    return axios.get(SERVER_URL);
}

export const addNewsData = (item) => {
    return axios.post(SERVER_URL, item);
}

export const updateNewsData = (itemId, item) => {
    return axios.put(SERVER_URL+"/"+itemId, item);
}
