import React from "react";
import { FormattedMessage } from 'react-intl';

import ImgYoutube from "../../../assets/projects/RIR/icons/youtube_icon.png";
import FB from "../../../assets/projects/RIR/icons/FB.png";

export const FacebookLink = ({ href, title }) => {
    return (
      <h5 className="description" style={{ textAlign: 'justify ' }}>
        <img src={FB} width="40px" style={{ marginRight: '14px' }} />
        <a href={href}>
          {title}
        </a>
      </h5>
    );
  };
  
  export const YouTubeLink = ({ href, messageId }) => {
    return (
      <h5 className="description" style={{ textAlign: 'justify ' }}>
        <img src={ImgYoutube} width="60px" />
        <a href={href}>
          <FormattedMessage id={messageId} />
        </a>
      </h5>
    );
  };
  
  export const TextBlock = ({ messageId }) => {
    return (
      <h5 className="description" style={{ textAlign: 'justify ' }}>
        <FormattedMessage id={messageId} />
      </h5>
    );
  };
  
  export const MoreDatailsLink = ({ href }) => {
      return (
        <h5 className="description" style={{ textAlign: 'justify ' }}>
          <a href={href}>
              <FormattedMessage id='more_details' />
          </a>
        </h5>
      );
  };

  export const YouTubePlayer = ({ href, width, height }) => {
    return (
        <h5 className="description" style={{ width: "100%" }}>
            <iframe
                width={width}
                height={height}
                src={href}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        </h5>
    );
};