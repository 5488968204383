import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl'
import { FormattedMessage } from 'react-intl'
import axios from 'axios';
import {getNewsData, updateNewsData} from '../components/api/dbNews';
//import {messages} from '../../../i18n/messages'
// reactstrap components

import {
    UncontrolledCarousel,
    Container,
    Row,
    Col,
} from "reactstrap";

import styled from "styled-components";
import {LOCALES} from "../../../i18n/locales";


const NewsContainer = styled(Container)`
  border: ${props => props.wborder}px solid lightblue;
`;
const TextItemDiv = styled.div`
    margin-bottom: 10px;
`;

const RIRNewsTr2Page = ({ currentLocale, handleChange, adminMode }) => {
    document.documentElement.classList.remove("nav-open");
    
    const [arrNews2, setArrNews2] = useState([]);

    const fetchFBData = async () => {
        
        const response = await getNewsData();
        
        setArrNews2(response.data.reverse());
        //setLoading(false);
          
    };

    useEffect(() => {
        fetchFBData();
    }, []);


    var options = {
        //era: 'long',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        //weekday: 'long',
        //timezone: 'UTC',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    };

    const showAllNews = (allNews, locale) => {
        if(!allNews)
            return;
        if(allNews.length <= 0)
            return;
            
        return (
            <div>
                {
                    allNews ? 
                    allNews.map((item) => (
                        <div className="section text-left">
                            {showNewsItem(item, locale)}
                        </div>
                        )
                    ) : null
                }
            </div>
        )
    };

    const showNewsItem = (newsItem, locale) => {
        if(!newsItem)
            return;
        const imgList = newsItem ?  newsItem.imgList : null;
        //const textList = "t1";//newsItem ?  newsItem.textList : null;
        const text_ukr = newsItem.text_ukr;
        const text_eng = newsItem.text_eng;
        const wBorder = 1;
        const text = newsItem.title;
        return (
            <NewsContainer wborder={wBorder}>
                
                {text ? <Row>
                    <h2 className="title">
                        <TextItemDiv>{text}</TextItemDiv>
                    </h2>
                </Row> : null}
                <Row>
                    <Col><h6 className="description">{newsItem.id} / {newsItem.date}</h6></Col>
                </Row>
                <Row>
                    <Col><h6 className="description">{newsItem.url} </h6></Col>
                </Row>
                <Row>
                    
                    <Col>
                    <p style={{whiteSpace: 'pre-wrap', border: "1px solid gray"}}>{text_ukr}</p>
                    </Col>
                    
                    <Col>
                    <p style={{whiteSpace: 'pre-wrap', border: "1px solid gray"}}>{text_eng}</p>
                    </Col>
                </Row>
            </NewsContainer>
        )
    };

    const showImages = (imgList) => {
        return (
            <div>
            {
                imgList ?
                imgList.map((item) => (
                    <img src={item} width="150px" />
                    )
                ) : null
            }
            </div>
        )
    };


    const OPENAI_KEY = "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";
    const [itemId, setItemId] = useState(62);

    const translate = async (inputText) => {
        try {
          const response = await axios.post(
            'https://api.openai.com/v1/completions',
            {
              prompt: `Translate the following Ukrainian text to English: ${inputText}`,
              max_tokens: 600,
              model: "text-davinci-003",
              temperature: 0,
            },
            {
              headers: {
                'Authorization': `Bearer ${OPENAI_KEY}`,
                'Content-Type': 'application/json'
              }
            }
          );
    
          return response.data.choices[0].text.trim();
          //setTranslatedText(response.data.choices[0].text.trim());
        } catch (error) {
          console.error(error);
        }
      };

    const addItem = async () => {
        
        let findItem = null;
        arrNews2.map((item) => {
            if (Number(item.id) === Number(itemId)) {

                
                //item.text_eng = "qqqq";
                findItem = item;
                console.log("=== "+item.id);
                
            }
        })

        if (findItem) {
            const eng = await translate(findItem.text_ukr);
            findItem.text_eng = eng;
            await updateNewsData(findItem.id, findItem);
            console.log("+++");
        }
        

        //setArrNews2(arrNews2);


        /*const item = {
            id: itemId,
            url: itemUrl,
            title: "",
            text_ukr: itemDetail,
            text_eng: "-",
            date: itemDate,
            imgList: [
                itemName1,
                itemName2,
                itemName3,
            ],
        };*/

        //await axios.post('https://tykobd1--olegkosmina.repl.co/api/items', item);
        //await axios.put(`https://tykobd1--olegkosmina.repl.co/api/items/${currentItemId}`, item);
        
        fetchFBData();
    };

    return (
        <>
        <div className="main">
        <div>

      <h2>CRUD Component</h2>
      <div>
        <label>ID:</label>
        <input 
          type="text" 
          value={itemId} 
          onChange={e => setItemId(e.target.value)} 
        />
        <button onClick={addItem}>translate</button>
        <button onClick={addItem}>update</button>
      </div>

    </div>
                    <NewsContainer wborder={1}>
                        
                        
                    </NewsContainer>

                    {showAllNews(arrNews2, currentLocale)}

            </div>

        </>
    );
}

export default RIRNewsTr2Page;
