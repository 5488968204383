import React from "react";
import ReactDOM from "react-dom";

import App from "../src/components/App.js";

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
  document.getElementById("root")
);
