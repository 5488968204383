import React from "react";
import { IntlProvider } from 'react-intl';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import RIRHomePage from "../projects/iyfr_region/pages/RIRHomePage";
import RIRNewsPage from "../projects/iyfr_region/pages/RIRNewsPage";
import RIRNewsTr2Page from "../projects/iyfr_region/pages/RIRNewsTr2Page";
import RIRNewsAddPage from "../projects/iyfr_region/pages/RIRNewsAddPage";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss?v=1.2.0";
import "assets/demo/demo.css?v=1.2.0";

import { LOCALES } from '../i18n/locales';
import { messages } from '../i18n/messages';

const App = () => {
    const [currentLocale, setCurrentLocale] = React.useState(getInitialLocale());

    const handleChange = (value) => {
        setCurrentLocale(value);
        localStorage.setItem('locale', value);
    };

    function getInitialLocale() {
        const savedLocale = localStorage.getItem('locale');
        return savedLocale || LOCALES.ENGLISH;
    }

    return (
        <IntlProvider
            messages={messages[currentLocale]}
            locale={currentLocale}
            defaultLocale={LOCALES.ENGLISH}
        >
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<RIRHomePage currentLocale={currentLocale} handleChange={handleChange} />} />
                    <Route path="/region" element={<Navigate to="/" replace />} />
                    
                    <Route path="/news" element={<RIRNewsPage currentLocale={currentLocale} handleChange={handleChange} adminMode={false} />} />
                    <Route path="/tr2fbpost" element={<RIRNewsTr2Page currentLocale={currentLocale} handleChange={handleChange} adminMode={false} />} />
                    <Route path="/addfbpost" element={<RIRNewsAddPage currentLocale={currentLocale} handleChange={handleChange} adminMode={true} />} />
                    
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </BrowserRouter>
        </IntlProvider>
    );
};

export default App;
