
import React from "react";

// reactstrap components
import { Button, Container } from "reactstrap";

// core components
import RZH_ICON from "../../../assets/projects/RIR/img/main_bg.jpg"
import {FormattedMessage} from "react-intl";

function RIRLandingHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div
        style={{
          backgroundImage:
            "url(" + RZH_ICON + ")",
          height: "300px"
        }}
        //lassName="page-header"
        data-parallax={true}
        ref={pageHeader}
      >
        <div className="filter2"
        />
        <Container>
          <div
              className="motto text-center"

          >

            <br /><br />
            <h1 style={{fontWeight: "bold", fontSize: "5rem", padding: "0px", color: "rgba(10,10,255,0.6"}}><FormattedMessage id='News' /></h1>
            <h3 style={{fontWeight: "bold", padding: "0px", margin: "50"}}>IYFR</h3>
            <h3 style={{fontWeight: "bold", color: "rgba(255,255,255,0.8"}}><FormattedMessage id='header_region' /></h3>
            <br />
          </div>
        </Container>
      </div>
    </>
  );
}

export default RIRLandingHeader;
